.table-wrapper {
  max-height: 500px;
  overflow-y: auto;
}

.search-container {
  display: grid;
  gap: 10px;
  padding: 10px;
}

.form-control {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
}

@media (min-width: 992px) {
  .search-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .search-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 767px) {
  .search-container {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 1200px) {
  .display-search {
    display: block !important;
  }
}

@media (min-width: 1201px) {
  .display-search {
    display: none !important;
  }
}

.link-button {
  background: none;
  border: none;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
.link-button:focus {
  outline: none;
}
.ant-popover-inner {
  width: 300px !important;
}
