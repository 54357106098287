$link-color:#000;
$primary-color:#655baa;
$howItWorkBg:#f1f0f7;
$featureIconColor:#B4A5F1;

$bodyFont:"Poppins", sans-serif;
$headingFont: "Montserrat", sans-serif;


.container{
    width: 100%;
    min-height: max-content;
    padding: 3rem 6rem;
    padding-bottom: 6rem;
    @media (max-width:1024px) {

        padding: 3rem 2rem;
        padding-bottom: 3rem;
    }
    @media (max-width:500px) {
        padding: 2rem 0;
    }
    h1{
        font-family: $headingFont;
        text-align: center;
        font-weight: 800;
        @media (max-width:500px) {
            font-size: 1.5rem;
        }
    }
    h6{
        text-align: center;
        color: #747474;
        font-weight: 600;
        margin-top: 2rem;
        font-size: 1.3rem;
        @media (max-width:500px) {
            margin-top: 1rem;
            font-size: 1rem;
        }
    }
    .textContainer{
        margin-top: 3rem;
        padding: 0 2rem;
        @media (max-width:500px) {
            margin-top: 2rem;
        }
    }
    p{
        font-size: 1.1rem;
        margin-top: 1rem;
        @media (max-width:500px) {
            margin-top: 0.5rem;
            font-size: 0.9rem;
        }
    }
    h3{
        margin-top: 3rem;
        margin-bottom: 2rem;
        font-size: 1.4rem;
        font-weight: 600;
        @media (max-width:500px) {
            margin-top: 1.5rem;
            font-size: 1.2rem;
        }
    }
    h4{
        font-size: 1.3rem;
        font-weight: 600;
        margin-top: 2rem;
        @media (max-width:500px) {
            margin-top: 1rem;
            font-size: 1rem;
        }
    }
    h5{
        font-size: 1.2rem;
        font-weight: 600;
        @media (max-width:500px) {
            margin-top: 2rem;
            font-size: 0.9rem;
        }
    }
    .boldText{
    
        font-weight: 600;
    }
    a{
        color: $primary-color;
    }
    ul{
        li{
            margin-top: 1rem;
        }
    }
}