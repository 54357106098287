.custom-table-container {
    border: 1px solid rgb(212, 196, 219);
    border-radius: 22px;
}

.custom-table {
    border-collapse: separate;
    border-spacing: 0;

    padding: 7px 25px;
}

.custom-table td,
.custom-table th {
  border: none;
}

.invoice-download-btn {
  cursor: pointer;
}

.first-cell {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  padding-left: 20px !important;  
}

.last-cell {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

.first-cell, .middle-cell, .last-cell {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}