$link-color:#000;
$primary-color:#655baa;
$howItWorkBg:#f1f0f7;
$featureIconColor:#B4A5F1;

$bodyFont:"Poppins", sans-serif;
$headingFont: "Montserrat", sans-serif;

.main{
    width: 100%;
    min-height: 100vh;
    margin: auto;
    max-width: 2000px;
   
}

.nav{
    width: 100%;
    padding: 2rem 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width:950px) {

        padding: 2rem;
    }
    @media (max-width:400px) {

        padding: 1rem;
    }
    .menu{
        display: none;
        @media (max-width:768px) {

            display: block;
        }
    }

    .logo{
        width: 200px;
        img{
            width: 100%;
            
        }
        @media (max-width:400px) {

            width: 150px;
        }
    }
    .navLeftSection{
        display: flex;
        align-items: center;
        @media (max-width:768px) {

            display: none;
        }
        .navLink{
            text-decoration: none;
            color:$link-color;
            font-weight: 600;
            font-size: 1.2rem;
            font-family: $bodyFont;
            margin-right: 2rem;
        }
        .selectedLink{
            color: $primary-color;
        }
    }


  
}
.MenuMobile{
    width: 100%;
    display: flex;
    flex-direction: column;
    .navLink{
        text-decoration: none;
        color:$link-color;
        font-weight: 600;
        font-size: 1.2rem;
        margin-top: 2rem;
        font-family: $bodyFont;
     
    }
    .selectedLink{
        color: $primary-color;
    }
    .buttonWrapper{
        margin-top: 2rem;
    }
}


.heroContainer{
    width: 100%;
    min-height: max-content;
    display: flex;
    @media (max-width:768px) {
       flex-direction: column;
        
    }

    .leftContainer{
        width: 60%;
        min-height: inherit;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 4rem 0;
        @media (max-width:768px) {
            width: 100%;
            
        }
        padding: 2rem;
        .textContainer{
            display: flex;
            flex-direction: column;
            align-items: center;
            
        }
        h1{
            color:$primary-color;
            font-weight: 800;
            text-align: center;
            font-size: 4.4rem;
            font-family: $headingFont;
            @media (max-width:1444px) {
                font-size: 3.5rem;
                
            }
            @media (max-width:1024px) {
                font-size: 3rem;
                
            }
            @media (max-width:400px) {
                font-size: 2rem;
                
            }
        }
        h2{
            color: $link-color;
            font-weight: 800;
            text-align: center;
            font-size: 3.5rem;
            font-family: $headingFont;
            @media (max-width:1444px) {
                font-size: 3.2rem;
                
            }
            @media (max-width:1024px) {
                font-size: 2.5rem;
                
            }
            @media (max-width:400px) {
                font-size: 1.7rem;
                
            }
        }
        p{
            font-size: 1.8rem;
            color: $link-color;
            font-weight: 400;
            text-align: center;
            margin-top: 1rem;
            font-family: $bodyFont;
            width: 60%;
            @media (max-width:1444px) {
                font-size: 1.5rem;
            }
            @media (max-width:1024px) {
                font-size: 1.2rem;
            }
            @media (max-width:768px) {
                width: 100%;
                
            }
        }
    }
    .rightContainer{
        width: 40%;
        min-height: inherit;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; 
        @media (max-width:768px) {
            width: 100%;
            
        }
        .imageContainer{
            width: 100%;
            img{
                width: 100%;
                
            }
        }
    }
}

.signupButton{
    padding: 0.5rem 2rem;
    border-radius: 2rem;
    font-weight: 600;
    border:none;
    font-size: 2rem;
    background: linear-gradient(170deg, #a6e5f2, #b185f0);
    box-shadow: 5px 5px 5px #00000028 ;
    margin-top: 2rem;
    font-family: $bodyFont;
    @media (max-width:1444px) {
        font-size: 1.8rem;
        
    }
    @media (max-width:1024px) {
        font-size: 1.5rem;
        margin-top: 1.5rem;
        
    }
    @media (max-width:400px) {
        font-size: 1.3rem;
        
    }
  

}

.howItWorks{
    width: 100%;
    min-height: max-content;
    background-color:$howItWorkBg ;
    padding: 4rem 8rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    @media (max-width:1444px) {

       
        padding: 2rem 4rem;
    }
    @media (max-width:768px) {

       
        padding: 2rem;
    }

    h2{
        font-size: 3rem;
        font-weight: 700;
        color: $link-color;
        text-align: center;
        font-family: $headingFont;
        @media (max-width:1444px) {
            font-size: 2.5rem;
        }
        @media (max-width:1024px) {
            font-size: 2rem;
        }

    }
    h5{
        font-size: 1.8rem;
        color: $link-color;
        font-weight: 400;
        text-align: center;
        margin-top: 1rem;
        font-family: $bodyFont;
        width: 60%;
        @media (max-width:1444px) {
            font-size: 1.5rem;
        }
        @media (max-width:1024px) {
            font-size: 1.2rem;
        }
        @media (max-width:768px) {
            width: 100%;
            
        }
    }
    .stepsContainer{
        margin-top: 4rem;
        width: 100%;
        display: flex;
        justify-content: space-between;
        @media (max-width:768px) {
            flex-direction: column;
            margin-top: 2rem;
            
        }

        .stepElm{
            display: flex;
            align-items: center;
            flex-direction: column;
            padding: 2rem;
            width: 50vmin;
            @media (max-width:768px) {
                width: 100%;
                
            }
            .iconElm{
                height: 4rem;
                img{
                    height: 100%;
                }
                @media (max-width:1444px) {
                    height: 3rem;
                }
            }
            h4{
                font-size: 1.7rem;
                font-weight: 700;
                color: $primary-color;
                text-align: center;
                margin: 2rem 0;
                font-family: $bodyFont;
                @media (max-width:1444px) {
                    font-size: 1.5rem;
                }
                @media (max-width:1024px) {
                    font-size: 1.2rem;
                }
                @media (max-width:768px) {
                    font-size: 1.5rem;
                     
                 }
            }
            h6{
                font-size: 1.2rem;
                color: $link-color;
                font-weight: 600;
                text-align: center;
                font-family: $bodyFont;
                @media (max-width:1444px) {
                    font-size: 1rem;
                }
                @media (max-width:1024px) {
                    font-size: 0.8rem;
                }
                @media (max-width:768px) {
                   font-size: 1rem;
                    
                }
            }

        }
    }
}

.subSectionContainer{
    width: 100%;
    min-height: max-content;
    display: flex;
    @media (max-width:768px) {
        flex-direction: column;
        
    }
    // background-color: yellow;
    .leftContainer{
        width: 60%;
        min-height: inherit;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // background-color: red;
        padding: 6rem 0;
        @media (max-width:768px) {
            width: 100%;
            
        }
        @media (max-width:1024px) {
            padding: 4em 0;
            
        }
        .textContainer{
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            // background-color: rgba(255, 0, 0, 0.301);
            .arrowImage{
                position: absolute;
                right:-25%;
                top:0%;
                width: 10rem;
                img{
                    width: 100%;
                }
                @media (max-width:1024px) {
                    width: 6rem;
                    top: 5%;
                    right: -15%;
                    
                }
                @media (max-width:768px) {
                    right: 0%;
                    // width: 15vw;
                    top: 10%;
                    
                }
         
            }
        }
 
        h2{
            color: $link-color;
            font-weight: 800;
            text-align: center;
            font-size: 3.5rem;
            font-family: $headingFont;
            position: relative;
            z-index: 1;
            @media (max-width:1444px) {
                font-size: 3.2rem;
                
            }
            @media (max-width:1024px) {
                font-size: 2.5rem;
                
            }
            @media (max-width:400px) {
                font-size: 1.7rem;
                
            }
        }
        p{
            color: $link-color;
            text-align: center;
            font-weight: 400;
            font-size: 1.5rem;
            margin-top: 1rem;
            font-family: $bodyFont;
            @media (max-width:1444px) {
                font-size: 1.2rem;
                
            }
            @media (max-width:1024px) {
                font-size: 1rem;
                
            }
            @media (max-width:400px) {
                font-size: 0.8rem;
                
            }

        }
        .signupButton{
            background: #000;
            color: #fff;
            font-size: 1.5rem;
            font-weight: 500;
            padding: 1rem;
            border-radius: 3rem;
            @media (max-width:1024px) {
                font-size: 1.2rem;
                margin-top: 1rem;
                
            }
            @media (max-width:400px) {
                font-size: 1rem;
                padding: 0.8rem;
                
            }
        }
    }
    .rightContainer{
        width: 40%;
        min-height: inherit;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        .imageContainer{
            width: 100%;
            position: relative;
            z-index: 2;
            img{
                width: 100%;

            }
        }
        .bubbleImage{
            position: absolute;
            transform: translate(-50%,-50%);
            left: 0;
            top: 60%;
            width: 5rem
            img{
                width: 100%;
            }
        
            @media (max-width:1024px) {
                width: 3rem;
                left: -10%;
            
            }
            @media (max-width:768px) {
                top: 0%;
                left: 20%;
                
                // width: 80vw;
                
            }
            @media (max-width:400px) {
                width: 2rem;
                
            }
    
          
        }
        @media (max-width:768px) {
            width: 100%;
            
        }
    }
}
.featureContainer{
    width: 100%;
    min-height: max-content;
    background-color:$howItWorkBg ;
    padding: 4rem 10rem;
    @media (max-width:1444px) {
        padding: 4rem 8rem;
    }
    @media (max-width:1024px) {
        padding: 4rem 6rem;       
    }
    @media (max-width:768px) {
        padding: 3rem 2rem;     
     }
    .featureElm{
        display: flex;
        align-items: center;
        width: 100%;
        flex-direction: row-reverse;
        @media (max-width:768px) {
            flex-direction: column;       
        }


      
        .featureText{
            display: flex;
            flex-direction: column;
            align-items: center;
            h3{
                font-size: 2.8rem;
                color: $link-color;
                text-align: center;
                font-family: $headingFont;
                font-weight: 700;
                @media (max-width:1444px) {
                    font-size: 2.5rem;
                   
                    
                }
                @media (max-width:1024px) {
                    font-size: 2.2rem;       
                }
                @media (max-width:768px) {
                    font-size: 1.8rem;       
                }
                @media (max-width:400px) {
                    font-size: 1.5rem;       
                }

            }
            p{
                font-size: 1.5rem;
                color: $link-color;
                text-align: center;
                width: 70%;
                font-family: $bodyFont;
                @media (max-width:1444px) {
                    font-size: 1.2rem;     
                }
                @media (max-width:1024px) {
                    font-size: 1rem;       
                }
                @media (max-width:768px) {
                    width: 100%;       
                }
                @media (max-width:400px) {
                    font-size: 0.8rem;       
                }
            }
        }
        .featureIcon{
            width: 20rem;
            color: $featureIconColor;
            margin-left: 2rem;
            @media (max-width:768px) {
               width: 10rem;    
               margin-bottom: 1rem;
               margin-left: 0;

            }
            @media (max-width:400px) {
                width: 6rem;      
            }
            margin-right: 0;
            img{
                width: 100%;
            }
        
      
        }
        

    }

    .featureElmWrapper{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
       
        &:first-child{
            justify-content: flex-end;
            margin-bottom: 4rem;
            @media (max-width:768px) {
                margin-bottom: 2rem;    
            }
            .featureElm{
                flex-direction: row;
                @media (max-width:768px) {
                    flex-direction: column;       
                }
                .featureIcon{
                    margin-right:2rem ;
                    margin-left: 0;
                    @media (max-width:768px) {
                        margin-right: 0;     
                     }
              
                }
            }
        }
    }


}

.leadsContainer{
    background-color: #655baa;
    width: 100%;
    padding: 10rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    @media (max-width:768px) {
        padding: 8rem 2rem;
        
    }
    
 
    h2{
        color: #fff;
        font-weight: 700;
        font-size: 3rem;
        text-align: center;
        width: 60%;
        font-family: $headingFont;
        @media (max-width:1024px){
            font-size: 2.5rem;
        }
        @media (max-width:768px){
            font-size: 2rem;
            width: 100%;
        }
        @media (max-width:450px){
            font-size: 1.5rem;
        }

    }
    h5{
        color: #fff;
        font-size: 1.8rem;
        margin-top: 4rem;
        font-family: $bodyFont;
        text-align: center;
        @media(max-width:1444px){
            margin-top: 2rem;
        }
        @media (max-width:1024px){
            font-size: 1.5rem;
        }
        @media (max-width:768px){
            font-size: 1.2rem;
            margin-top: 2rem;
        }
        @media (max-width:450px){
            font-size: 1rem;
            margin-top: 1rem;
        }
     
    }
    .signupButton{
        background: #000;
        color: #fff;
        font-size: 1.5rem;
        font-weight: 500;
        padding: 1rem 1.5rem;
        border-radius: 3rem;
        @media (max-width:1024px){
            font-size: 1.3rem;
            padding: 0.8rem 1rem;
        }
        @media (max-width:768px){
            font-size: 1rem;
        }
  
    }
    .leadsTxtWrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 2;
    }
    .starBgImg{
        position: absolute;
        left: 10%;
        top:5%;
        width: 20rem;
        @media (max-width:768px){
            width: 10rem;
        }
        img{
            width: 100%;
        }
    }
}


.footer{
    width: 100%;
    background-color: #655baa;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0rem 3rem;
    padding-top: 2rem;
    padding-bottom: 1rem;
    border-top: 2px solid #ffffff4b;
    @media (max-width:1024px) {
        padding: 0 4rem;
        padding-top: 4rem;
        
    }
    @media (max-width:768px) {
        padding: 0;
        padding-top: 4rem;
            
        }

    
    .footerLinksContainer{
        width: 100%;
        height: 8rem;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-template-rows: repeat(2,1fr);
           @media (max-width:768px) {
            height: 4rem;
                
            }
        
        .footerLink{
            color:#fff;
            font-size: 1.2rem;
            text-align: center;
            font-family: $bodyFont;
            @media (max-width:1024px) {
                font-size: 1.4rem;
                
            }
            @media (max-width:768px) {
                font-size: 1.2rem;
                    
                }
                @media (max-width:768px) {
                    font-size: 1rem;
                        
                    }
         
        }

    }
    .socialLinksContainer{
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 1rem;
        .socialIcon{
            color: #fff;
            margin: 0 1rem;
            font-size: 1.5rem;
            @media (max-width:1024px) {
                font-size: 1.6rem;
                
            }
            @media (max-width:768px) {
                font-size: 1.2rem;
                    
                }
                @media (max-width:768px) {
                    font-size: 1rem;
                        
                    }
    
        }
    }
    .copywriteText{
        color: #fff;
        font-size: 1rem;
        font-weight: 300;
        margin-top: 1rem;
        font-family: $bodyFont;
        @media (max-width:1024px) {
            font-size: 1.2rem;
            
        }
        @media (max-width:768px) {
            font-size: 1rem;
                
            }
            @media (max-width:768px) {
                font-size: 0.8rem;
                    
                }

    }

}


.paintBrushBg{
    display: inline-block;
    position: relative;
    img{
        width: 120%;
        position: absolute;
        transform: translate(-50%,-50%);
        top:50%;
        left: 50%;
    }
    .paintBrushTxt{
        position: relative;
        display: inline-block;
        z-index: 1;
    }
}