.card-body {
  display: flex;
  flex-direction: column;
  margin-top: 19%;
}
.card-row{
  justify-content: center;
}

.card-row-btn {
  margin: 15% 0% !important;
}

.subscribe-btn{
  color: white !important;
  background: #655BAA !important;
  border-radius: 5px !important;
  border-color: rgb(110 108 129) !important;
  width: 14rem;
}

.subscribe-btn:hover{
  color: #655BAA !important;
  background: white !important;
}

@media (min-width: 576px) {
  .subscription-card {
    width: 30rem;
  }
}
