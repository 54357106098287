.reward-dashboard {
  display: block;
  padding: 0 15px;
}

.table-wrapper {
  max-height: 500px;
  overflow-y: auto;
}

.table-wrapper thead th {
  position: sticky;
  top: 0;
  background: #f8f9fa;
  z-index: 1;
}

.table-wrapper tbody tr:hover {
  background-color: #f1f1f1;
}

@media (max-width: 1200px) {
  .reward-dashboard {
    margin-top: 10%;
  }
}

@media (max-width: 767px) {
  .table-wrapper {
    max-height: 300px;
  }
}
