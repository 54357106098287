.profileUpload{
    cursor: pointer;
    position: relative;
    overflow: hidden;
    border-radius: 100%;
    &::after{
        content: "Upload Logo";
        font-weight: 600;
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.651);
        top:0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        opacity: 0;
        transition: opacity cubic-bezier(0.165, 0.84, 0.44, 1) 1s;
    }
    &:hover::after{
        opacity: 1;
    }
}


.submitButton{
    width: 25%;
    @media(max-width:992px) {
        width: 50%;    
    }

}