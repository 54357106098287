.checkbox-button {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: #f8f9fa;
  color: #495057;
}

.checkbox-button:before {
  content: '';
  display: inline-block;
  width: 1.7rem;
  height: 1.7rem;
  border: none;
  border-radius: 0.25rem;
  background-color: #fff;
  vertical-align: middle;
}

.checkbox-button-primary.active:before,
.checkbox-button-secondary.active:before {
  content: '✔';
  display: inline-block;
  color: #655BBA;
  text-align: center;
}

.reset-selection-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #655BBA;
  color: #655BBA;
  background-color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  margin-left: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.reset-selection-button:hover {
  background-color: #655BBA !important;
  color: #fff !important;
}

.reset-selection-button:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(101, 91, 186, 0.5);
}

.reset-selection-button:active {
  background-color: #655BBA;
  color: #fff;
}
