$featureIconColor:#B4A5F1;

.pricingContainer{
    width: 100%;
    min-height: max-content;
    padding: 4rem;
    @media (max-width:768px) {
        padding: 2rem;
    }
}

.textContainer{
    display: flex;
    flex-direction: column;
    align-items: center;

    .mainTxt{
        font-weight: 800;
        text-align: center;
        font-size:4rem;
        
        @media (max-width:1024px) {
            font-size:3rem;
        }
        @media (max-width:750px) {
            font-size:2.5rem;
        }
        @media (max-width:640px) {
            font-size:2rem;
        }
        @media (max-width:508px) {
            font-size:1.5rem;
        }
    }

    .subTxt{
        text-align: center;
        margin-top: 1rem;
        @media (max-width:1024px) {
            font-size:24px;
        }
        @media (max-width:750px) {
            font-size:20px;
        }
        @media (max-width:640px) {
            font-size:16px;
        }
        @media (max-width:508px) {
            font-size:13px;
        }
    }
}


.heroContainer{
    width: 100%;
    margin-top: 6rem;
    display: flex;
    align-items: center;
    @media (max-width:768px) {
        flex-direction: column;
        margin-top: 2rem;
    }

    .leftContainer{
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        @media (max-width:768px) {
            width: 100%;
            padding: 0;
        }
        
        .textContainer{
            display: flex;
            padding: 8rem;
            background: linear-gradient(170deg, #c2d9fc, #ece2fd);
            align-items: center;
            border-radius: 2rem;
            border:none;
            h5{
                font-size:4rem;
                @media (max-width:450px) {
                   font-size: 2rem;
                }
                .subTxt{
                    font-size:2rem;
                    @media (max-width:450px) {
                        font-size: 1rem;
                     }
                }
            }
        
            @media (max-width:1123px){
                padding: 6rem;
               
            
            }
            @media (max-width:955px){
                padding: 5rem;
                   
               }
               @media (max-width:768px) {
                padding: 4rem;
            }
        }
        padding: 2rem;
    }

    .rightContainer{
        padding: 2rem;
        width: 50%;
        @media (max-width:768px) {
            width: 100%;
            padding: 0 4rem;
            margin-top: 2rem;
        }
        @media (max-width:450px) {
            width: 100%;
            padding: 0 2rem;
            margin-top: 2rem;
        }

        .LinksContainer{
       
            .socialLinkElm{
                display: flex;
                gap:1.2rem;
                margin-top: 2.4rem;
                @media (max-width:1123px){
                 gap: 1rem;
                 margin-top: 1.5rem;
                    
                }
                @media (max-width:768px) {
                    margin-top: 1rem;
                
                }
               
                .Icon{
                    font-size: 2.4rem;
                    color: $featureIconColor;
                    @media (max-width:768px) {
                        font-size: 1.2rem;
                    }
                }

                p{
                    font-size: 1.8rem;
                    line-height: 1.8rem;
                    margin-bottom: 0;
                    @media (max-width:1123px){
                        font-size: 1.4rem;
                        line-height: 1.4rem;
                    
                    }
                    @media (max-width:768px) {
                        font-size: 1rem;
                    }
            
                }
                &:first-child{
                    margin-top: 0;
                }

            }
        }
    }
}

.signupButton{
    background: #655baa;
    color: #fff;
    font-size: 1.8rem;
    font-weight: 500;
    margin-top: 2rem;
    padding: 1rem 1.5rem;
    border-radius: 3rem;
    border:none;
    @media (max-width:1304px) {
        font-size: 1.5rem;
    }
    @media (max-width:1165px) {
        padding: 0.8rem 1.2rem;
    }
    @media (max-width:1039px) {
        font-size: 1rem;
    }
    @media (max-width:750px) {
        font-size: 0.8rem;
        padding: 0.8rem 1rem;
        margin-top: 1rem;
        
    }
}