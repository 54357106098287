.subscription-container {
  margin-left: 0;
  padding-left: 0;
}

.subscription-card-body {
  display: flex;
  flex-direction: column;
  margin-top: 0% !important;
}

.cancel-subscribe-btn {
    color: #655BAA !important;
    background-color: transparent !important;
    border: none;
    font-weight: 600;
    padding-left: 0;
    margin-top: 5px;
}

@media (min-width: 576px) {
  .subscription-card-details {
    width: 25rem;
  }
}

@media (min-width: 768px) {
  .subscription-card-details {
    width: 30rem;
  }
}

@media (min-width: 992px) {
  .subscription-card-details {
    width: 35rem;
  }
}