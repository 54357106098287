body {
  font-family: Arial, sans-serif;
  /* background-color: #f8f9fa !important; */
}

.text-center {
  text-align: center;
}

.btn-primary {
  background: #655baa !important;
  color: #ffffff !important;
  border: none !important;
}

.link {
  color: #655baa;
}

.ant-menu-item {
  display: flex !important;
  color: #ffffff !important;
  align-items: center;
}

.ant-menu {
  background-color: #655baa !important;
}

.ant-menu-item-selected {
  background-color: #331d66 !important;
  color: #ffffff !important;
  border-right: 3px solid #9695a3;
}

.ant-menu-item:hover {
  background-color: #331d66 !important;
  color: #ffffff !important;
  border-right: 3px solid #9695a3;
}

.ant-layout-sider-trigger {
  background-color: #655baa !important;
}

.ant-layout-sider-trigger {
  display: none;
}

.ant-layout-sider {
  min-width: 300px !important;
}

.edit-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.edit-content {
  width: 100%;
  max-width: 800px;
}

.edit-form-container {
  margin-top: 20px;
}

.edit-card {
  margin-bottom: 20px;
}

.edit-card .card-body {
  padding: 20px;
  margin-top: 0px !important;
}

@media (max-width: 768px) {
  .edit-content {
    padding: 0 10px;
  }
}

.edit-client-employee-form {
  font-weight: bold;
}

.ant-drawer-content-wrapper {
  width: 266px !important;
}

.form-label {
  font-size: 1rem !important;
  font-weight: bold !important;
}

.nav-link.active {
  color: #655baa !important;
  background-color: transparent !important;
}

.nav-link {
  color: #b2aae6 !important;
  background-color: transparent !important;
  font-size: 1.2rem !important;
  font-weight: bold !important;
}

@media (max-width: 767px) {
  .pb-xs-3 {
    padding-bottom: 0.75rem !important;
  }
}

.input-error {
  color: red;
  margin-top: 0.5rem;
}



.ant-collapse-header-text{
  font-family: "Poppins", sans-serif;
  font-size: 1.7rem;
  font-weight: 700;
 @media (max-width:1024px) {

    font-size: 1.5rem;
 }
 @media (max-width:768px) {
  font-size: 1.2rem;
  }
}

.ant-collapse-expand-icon svg{
  
      font-size: 2rem !important;
      font-weight: 800 !important;
      stroke-width: 4px;
      @media (max-width:768px) {
        font-size: 1.5rem !important;
     }
     @media (max-width:450px) {
      font-size: 1rem !important;
   }
  
}

.upload-type-select{
  width: 100% !important;
}

@media (max-width: 992px) {
  .responsive-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .upload-type-select{
    width: 100% !important;
  }

  .responsive-container > div {
    margin-right: 0;
    margin-bottom: 10px;
    width: 100%;
  }
}
@media (max-width: 375px) {
  .custom-responsive-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .custom-responsive-container > div {
    margin-right: 0;
    margin-bottom: 10px;
    width: 100%;
  }
}
.file-label {
  cursor: pointer;
  color: #655baa;
  font-weight: 500;
  border: 1px solid rgb(212, 196, 219);
  font-size: 16px;
  padding: 4px 15px;
  border-radius: 6px;
  height: 40px;
  line-height: 22px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.file-label:hover {
  background-color: #655baa;
  color: white;
  border-color: #655baa; 
}