.container-custom {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.card-custom {
  max-width: 600px;
  width: 100%;
  margin: 2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-body-custom {
  margin: 0;
  padding: 2rem;
}

.card-title {
  margin-bottom: 1.5rem;
  text-align: center;
}

.card-subtitle {
  margin-bottom: 1.5rem;
}
