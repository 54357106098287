$link-color:#000;
$primary-color:#655baa;
$howItWorkBg:#f1f0f7;
$featureIconColor:#B4A5F1;

$bodyFont:"Poppins", sans-serif;
$headingFont: "Montserrat", sans-serif;


.faqContainer{
    width: 100%;
    padding: 3rem 6rem;
    min-height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width:1024px) {

        padding: 2rem;
     }
    h1{
        font-family: $headingFont;
        font-weight: 700;
        font-size: 3rem;
        @media (max-width:1024px) {

            font-size: 2.5rem;
         }
         @media (max-width:768px) {
            font-size: 2rem;
            }
    }
    h6{
        font-family: $bodyFont;
        font-size: 1.8rem;
        font-weight: 500;
        margin-top: 0.5rem;
        text-align: center;
        @media (max-width:1024px) {

            font-size: 1.5rem;
         }
         @media (max-width:768px) {
            font-size: 1.3rem;
            }
    }
    .faqAccordionWrapper{
        margin-top: 2rem;
        width: 90%;
        @media (max-width:450px) {
            width: 95%;
            }
    }

}

.accordionIcon{
    color:#000;
    font-size: 5rem;
}

.discriptionTxt{
    color: $link-color;
    font-size: 1.4rem;
    font-family: $bodyFont;
    @media (max-width:1024px) {

        font-size: 1.2rem;
     }
     @media (max-width:768px) {
        font-size: 1rem;
        }
}

